.ani-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(33,33,33,.2); */
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-to-cart-ani {
  width: 64px;
  height: 64px;
  border: 2px solid var(--color-primary);
  background-color: white;
  border-radius: 50%;
  position: absolute;
  animation-name: moveToBag;
  animation-duration: .8s;
  animation-timing-function: linear;
}


@keyframes moveToBag {
  0.0000% { transform: translate(0.0px, 0.0px); }
  28.3802% { transform: translate(6.3vw, -18.6vh) scale(0.8); }
  53.6290% { transform: translate(16.1vw, -33.0vh) scale(0.65); }
  76.6470% { 
    transform: translate(29.1vw, -42.2vh) scale(0.45); 
    opacity: 1;
  }
  100.0000% { 
    transform: translate(45.0vw, -45.0vh) scale(0.3); 
    opacity: 0;
  }
}