
.loading-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33,33,33,.2);
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-backdrop svg {
  width: 47px !important;
  height: auto !important;
}

.loading-backdrop p {
  color: var(--color-primary-dark);
  font-size: 16px;
}

.loading-backdrop p {
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading-backdrop p:nth-child(2) {
  animation-delay: .1s; 
}
.loading-backdrop p:nth-child(3) {
  animation-delay: .2s;
}
.loading-backdrop p:nth-child(4) {
  animation-delay: .3s; 
}
.loading-backdrop p:nth-child(5) {
  animation-delay: .4s;
}
.loading-backdrop p:nth-child(6) {
  animation-delay: .5s; 
}
.loading-backdrop p:nth-child(7) {
  animation-delay: .6s;
}
.loading-backdrop p:nth-child(8) {
  animation-delay: .8s;
}
.loading-backdrop p:nth-child(9) {
  animation-delay: 1s;
}
.loading-backdrop p:nth-child(10) {
  animation-delay: 1.2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0px)
  }
  40% {
    transform: translateY(-8px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}

@keyframes cake-breath {
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: scale(0.9099999999999999);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    transform: scale(1.02994);
  }
  100% {
    transform: scale(0.9099999999999999);
  }
}