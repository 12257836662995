#root {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.top-bar {
  position: fixed;
  top:0;left:0;right:0;
  height: 48px;
  overflow: hidden;
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px
}

a {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  text-decoration: none;
}
.hover-box{
  transition: box-shadow .3s;
}
.hover-box:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.row.no-gutters {
  --bs-gutter-x: 0px;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-justify {
  text-align: justify !important;
}

@media screen and (min-width: 767px){
  .text-md-right {
    text-align: right !important;
  }
}
/* xlxlxlxl */

:root {
  --color-primary: #e6c9cb;
  --color-primary-dark: #7c4e51;
}


.xl-menu {
  font-family: 'Dancing Script', cursive;
  font-size: 14px;
  background-color: white;
  z-index: 1001;
  padding: 0 7.5vw
}

.xl-menu a{
  font-family: 'Dancing Script', cursive !important;
  font-size: 14px !important;
  color: #171717;
  font-weight: bold;
}
.xl-menu i{
  font-size: 20px !important;
  color: #a0a2a4;
  font-weight: bold;
}
.xl-menu a:hover {
  color: var(--color-primary-dark);
}

.xl-menu .burger {
  position: relative;
  float: left;
  margin: 12px 20px;
  padding: 0px;
  display: none;
  list-style: none;
  align-self: center;
}

.xl-menu .burger li {
  width: 30px;
  height: 4px;
  background-color: #171717;
  border-radius: 3px;
  margin: 5px 0;
}

.xl-menu-logo {
  max-width: 240px;
  flex: 1 0 240px;
  overflow: hidden;
}

.xl-menu-logo a,  
.xl-menu-logo img{
  width: auto;
  max-width: 128.625px;
}

@media (max-width: 1199px) {
  .xl-menu .nav-bar{
    display: none !important;
  }
}

@media only screen and (max-width: 1199px){
  .xl-menu {
    padding: 0 2.5vw
  }
  .xl-menu .burger {
      display: block;
  }
}

@media only screen and (max-width: 600px){
  .xl-menu-logo {
    max-width: 120px;
    flex: 1 0 120px;
  }
}

.xl-menu-nav {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 1002;
  background-color: transparent;
  /*border-radius: 8px;*/
  /* starting point */
  opacity: 0;
  -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
  -moz-transform: translate3d(5%, 0, 0) scale(0.97);
  transform: translate3d(5%, 0, 0) scale(0.97);
}

.xl-menu-nav.open-menu {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  -webkit-animation: slideIn 0.35s ease-in-out;
  -moz-transform: translate3d(0, 0, 0) scale(1);
  -moz-animation: slideIn 0.35s ease-in-out;
  transform: translate3d(0, 0, 0) scale(1);
  animation: slideIn 0.35s ease-in-out;
}

.xl-menu-nav.open-menu + .inner-page {
  -webkit-transform: translate3d(280px, 0, 0);
  -webkit-animation: open 0.5s ease-in-out;
  -moz-transform: translate3d(280px, 0, 0);
  -moz-animation: open 0.5s ease-in-out;
  transform: translate3d(280px, 0, 0);
  animation: open 0.5s ease-in-out;
  z-index: 1001;
}

.xl-menu-nav.open-menu + .inner-page .xl-menu {
  position: unset !important;
}

.xl-menu-nav.open-menu + .inner-page > div {
  margin-top: 0 !important;
}

.xl-menu-nav .inner-menu {
  background-color: white;
  width: 280px;
  z-index: 1010;
  padding-top: 40px;
  padding-left: 16px;
}

.xl-menu-nav .inner-menu .menu-item {
  padding: 10px;
}

.xl-menu-nav .inner-menu a{
  font-family: 'Dancing Script', cursive !important;
  font-size: 14px !important;
  color: #171717;
}

.xl-menu-nav .backdrop {
  display: none;
  width: calc(100% - 280px);
  height: 100%;
  background: rgba(33,33,33,.2)
}

.xl-menu-nav.open-menu .backdrop {
  display: block;
}
@-webkit-keyframes slideIn {
  0% {
    opacity: 0.3;
    -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@-moz-keyframes slideIn {
  0% {
    opacity: 0.3;
    -moz-transform: translate3d(5%, 0, 0) scale(0.97);
  }

  100% {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0.3;
    transform: translate3d(5%, 0, 0) scale(0.97);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.xl-menu-nav.close-menu {
  opacity: 0;
  display: none;
  -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
  -webkit-animation: slideOut 0.3s ease-in-out;
  -moz-transform: translate3d(5%, 0, 0) scale(0.97);
  -moz-animation: slideOut 0.3s ease-in-out;
  transform: translate3d(5%, 0, 0) scale(0.97);
  animation: slideOut 0.3s ease-in-out;
}

@-webkit-keyframes slideOut {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }

  100% {
    opacity: 0.3;
    -webkit-transform: translate3d(5%, 0, 0) scale(0.97);
  }
}

@-moz-keyframes slideOut {
  0% {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0) scale(1);
  }

  100% {
    opacity: 0.3;
    -moz-transform: translate3d(5%, 0, 0) scale(0.97);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }

  100% {
    opacity: 0.3;
    transform: translate3d(5%, 0, 0) scale(0.97);
  }
}

.p-relative {
  position: relative !important;
}

.badge {
  position: absolute;
  border-radius: 50000px;
  background-color: red;
  width: auto;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  color: white;
  padding: 0;
  right:0px;
  top:-4px;
}

.page-top-container {
  height: calc(100vh - 90px);
  min-height: 645px;
  width: 100%;
  display: flex;
  position: relative;
}

.timer-container {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 320px;
  width: 33%;
  max-width: 640px;
  z-index: 10;
}
.timer-container .row>div{
  min-height: 360px;
}
.timer-container .row>div h2{
  font-size: 38px;
  font-weight: normal;
  line-height: 46px;
  margin-bottom: 0;
  margin-top: 0;
}
.timer-container .row>div p{
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
}

.timer-container .row>div h6{
  font-size: 18px;
  font-weight: normal;
  vertical-align: bottom;
  line-height: 36px;
}
.timer-container .row>div h6 span{
  font-size: 32px;
  font-weight: 900;
  line-height: 36px;
  font-family: 'Montserrat';
}
.timer-container .row>div h2,
.timer-container .row>div p, 
.timer-container .row>div h6{
  color: white;
}

.timer-container .time-box {
  border-radius: 16px;
  background-color: white;
  min-height: 64px;
  min-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
}

.timer-container .time-box h4 {
  font-size: 32px;
  font-weight: 700;
  font-family: 'Montserrat';
  line-height: 36px !important;
}
.timer-container .time-box p {
  margin-top: -20px;
  font-size: 12px;
  line-height: 12px !important;
}
.timer-container .time-box b {
  font-size: 12px;
  line-height: 12px !important;
}

.timer-container .time-box h4, 
.timer-container .time-box p,
.timer-container .time-box b {
  color: #171717 !important
}

.hide-btn {
  position: absolute;
  right: 12px;
  top: 12px;
}

.show-btn {
  position: fixed;
  right: 12px;
  bottom: 12px;
  z-index: 20;
  text-align: left;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px){

  .timer-container {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 320px;
    width: 33%;
    max-width: 640px;
    z-index: 10;
  }

  .timer-container .row>div{
    min-height: 240px;
  }
} 
.social-bar {
  background-color: #ededed;
  height: 100%;
  width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-bar>div{
  width: 100%;
  text-align: center;
  padding: 16px;
}

.social-bar>div a {
  color:#171717;
}

.social-bar>div i{
  font-size: 18px;
}

.h-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  line-height: 32px;
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  color:#171717;
}

@media (max-width: 480px) {
  .social-bar {
    width: 48px;
  }

  .h-text {
    line-height: 16px;
  }
}

.slide-title {
  font-size: 108px;
  color: #171717;
  font-weight: lighter;
  line-height: 120px;
}

@media (max-width: 992px) {
  .slide-title {
    font-size: 60px;
    line-height: 72px;
  }
}

@media (max-width: 768px) {
  .slide-title {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (max-width: 480px) {
  .slide-title {
    font-size: 32px;
    line-height: 36px;
  }
}

.slide-cat {
  font-size: 14px;
  color: #171717;
}

.slide-cat span {
  font-size: 18px;
}

.slide-desc {
  color: #535353;
  line-height: 28px;
}

.slide-btns {
  display: flex;
}

.slide-btns>div {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #171717;
  text-align: center;
  margin-right: 24px;
  cursor: pointer;
}
.slide-btns>div:hover {
  border: 1px solid transparent;
  background-color:#e6c9cb;
  cursor: pointer;
}

.slide-btns.large>div {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
}
.footer~.slide-btns>div {
  margin: 24px 12px;
}
.order-btn {
  color: #303030;
  font-size: 12px;
  background: #e6c9cb;
  border-radius: 50000px;
  width: 114px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 1480px) {
  .px-xxl-1 {
    padding-right: 0.25rem!important;
    padding-left: 0.25rem!important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
  }
  .px-xxl-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
  }
  .px-xxl-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important;
  }
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: var(--color-primary-dark);
  cursor:pointer;
}

.clickable:hover a {
  color: var(--color-primary-dark);
  cursor:pointer;
}

.clickable a:hover {
  color: var(--color-primary-dark);
  cursor:pointer;
}

.section-title {
  font-family: 'Dancing Script', cursive;
  font-size: 36px;
  letter-spacing: 3px;
  color: #2d2b27;
}

.section-subtitle {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  letter-spacing: 4px;
  color: #1a1a1a;
}

.section p {
  color: #2d2b27;
  font-size: 18px;
}

.category-btn {
  background-color: #939597;
  border-radius: 50000px;
  min-height: 60px;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
  margin-bottom: 48px;
  cursor: pointer;
}

.seasonal-banner {
  min-height: 280px;
}

.seasonal-banner .content{
  background-color: #e6c9cb;
  background: linear-gradient(90deg, rgba(230,201,203,1) 0%, rgba(230,201,203,1) 55%, transparent 100%);
}
.seasonal-banner .content>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.seasonal-banner .content .title {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.seasonal-banner .content .desc {
  letter-spacing: 8px;
}

.seasonal-banner .content .buy-btn {
  color: white;
  font-size: 16px;
  background: #000000;
  border-radius: 50000px;
  width: 240px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}

.faq-btn {
  width: 80%;
  height: 80px;
  color: #1a1a1a;
  background-color: white;
  margin-top: -40px;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}

.footer{
  width: 100%;
  background-color: #e6c9cb;
}
.footer p {
  color: #6e6e6e;
  font-size: 15px;
  font-weight: 100;
}

.sidebar {
  padding-bottom: 0 !important;
  z-index: 1002 !important;
  position: relative;
}

.shopping-cart {
  width: 100%;
  height: calc(100vh - 90px - 16px - 72px);
  margin-top: calc(90px);
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

.footer-btns {
  position: absolute;
  height: 72px;
  width: 100%;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #e6c9cb;
  bottom: 0;
}

.modal {
  top: unset;
  left: unset;
  height: unset;
  overflow: unset;
  outline: unset;
}
.swal-button, .swal-button:active, .swal-button:not([disabled]):hover{
  background-color: #e6c9cb;
  color: #303030;
  border-radius: 50000px;
}

.red {
  color: #ec2d2d;
}

.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus{
  border-color: #e6c9cb !important
}

.float-btn {
  position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.float-btn a i {
  font-size:30px;
	color:#FFF;
}

@media screen and (max-width:480px) {
  .float-btn {
    bottom:20px;
    left:20px;
  }

  .timer-container .row>div h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .timer-container .row>div p {
    font-size: 14px;
    line-height: 18px;
  }

  .timer-container .row>div h6 span {
    font-size: 24px;
  }

  .timer-container .row>div h6 {
    font-size: 14px;
  }

  .timer-container .order-btn {
    width: 96px;
  }

  .timer-container .time-box h4 {
    font-size: 28px;
  }
}

.ui.dimmer {
  z-index: 1003 !important;
}

.ui.modal {
  z-index: 1004 !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

pre {
  white-space: pre-wrap;
}